import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Modal, Table, Button, Switch, Space, Divider } from "antd";
import TableBar from "components/TableBar";
import { parseQueryParams, stringifyQueryParams } from "utils/url";
import { TrashIcon, PencilAltIcon, LoginIcon } from "@heroicons/react/outline";
import PageTitle from "components/PageTitle";
import UserCreateForm from "routes/users/UserCreateForm";
import UserUpdateForm from "./UserUpdateForm";
import { useSelector } from "react-redux";
// requests
import {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
  assignUsersToParent,
  unassignUsersFromParent,
  fetchUserAccessToken
} from "requests/user";
import UserAssignForm from "./UserAssignForm";
import moment from "moment";
import { removeCookie, setCookie } from "utils/cookie";

const UserList = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(
    process.env.REACT_APP_RECORDS_PER_PAGE
  );
  const [totalCount, setTotalCount] = useState(0);
  const [records, setRecords] = useState([]);
  const [visibleCreateForm, setVisibleCreateForm] = useState(false);
  const [visibleUpdateForm, setVisibleUpdateForm] = useState(false);
  const [visibleAssignForm, setVisibleAssignForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const authUser = useSelector((state) => state.auth.authUser);

  const location = useLocation();
  const navigate = useNavigate();

  const titles = [{ path: location.pathname, title: "Users" }];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Link to={`${location.pathname}/${record.id}`}>{text}</Link>
      ),
      sorter: true,
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
      ellipsis: true,
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        return <div>{record.role?.name || ""}</div>;
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (text, record) => {
        return <div>{record.country?.name || ""}</div>;
      },
    },

    // {
    // 	title: 'Email verified',
    // 	dataIndex: 'email_verified_at',
    // 	key: 'email_verified_at',
    // 	render: (text, record) => {
    // 		const date = text?.split(' ')[0];
    // 		const time = text?.split(' ')[1];
    // 		return (
    // 			<Space direction="vertical" align="center">
    // 				<span>{date}</span>
    // 				<span>{time}</span>
    // 			</Space>
    // 		);
    // 	},
    // },
    // {
    // 	title: 'Mobile verified',
    // 	dataIndex: 'mobile_verified_at',
    // 	key: 'mobile_verified_at',
    // 	render: (text, record) => {
    // 		const date = text?.split(' ')[0];
    // 		const time = text?.split(' ')[1];
    // 		return (
    // 			<Space direction="vertical" align="center">
    // 				<span>{date}</span>
    // 				<span>{time}</span>
    // 			</Space>
    // 		);
    // 	},
    // },
    {
      title: "Parent",
      dataIndex: "parent_id",
      key: "parent_id",
      render: (text, record) => {
        if (record.parent) {
          return (
            <div>
              <Link to={`/users/${record.parent.id}`}>
                {record.parent.full_name}
              </Link>
              <div
                className="pointer danger"
                onClick={() => onUnassign(record.id)}
              >
                <small>Detach</small>
              </div>
            </div>
          );
        }

        return null;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <Switch
            checked={text === 0 ? false : true}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={(value) => onUpdateStatus(record.id, value)}
          />
        );
      },
    },
    {
      title: "Date created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        return <div>{moment(text).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      title: "Actions",
      render: (text, record) => (
        <Space>
          <Button type="link" size="small" onClick={() => onEdit(record)}>
            <PencilAltIcon width={24} height={24} />
          </Button>
          <Button type="link" size="small" onClick={() => onAccessMerchantDashboard(record.id)}>
            <LoginIcon width={24} height={24} />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const query = parseQueryParams(location);
    getRecords(query);
  }, [location]);

  const onChangeTable = (pagination, filters, sorter, extra) => {
    console.log(pagination, filters, sorter, extra);

    let query = parseQueryParams(location);
    query = {
      ...query,
      page: pagination.current,
      per_page: pagination.pageSize,
    };

    if (sorter.order) {
      query = {
        ...query,
        order_by: sorter.field,
        order_type: sorter.order === "ascend" ? "asc" : "desc",
      };
    } else {
      delete query.order_by;
      delete query.order_type;
    }

    navigate({
      pathname: location.pathname,
      search: stringifyQueryParams(query),
    });
  };

  const onSearch = (keyword) => {
    let query = parseQueryParams(location);
    query = {
      ...query,
      page: 1,
      keyword: keyword,
    };

    navigate({
      pathname: location.pathname,
      search: stringifyQueryParams(query),
    });
  };

  const getRecords = async (query) => {
    try {
      const response = await getUsers(query);
      setRecords(response.records);
      setPage(response.page);
      setPerPage(response.per_page);
      setTotalCount(response.total_records);
    } catch (err) {
      console.log(err);
    }
  };

  const onToggleCreateForm = () => {
    setVisibleCreateForm(!visibleCreateForm);
  };

  const onCreate = async (data) => {
    try {
      await createUser(data);
      // refresh list
      navigate({
        pathname: location.pathname,
        search: stringifyQueryParams({}),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onEdit = (data) => {
    setSelectedUser(data);
    onToggleUpdateForm();
  };

  const onAccessMerchantDashboard = async (id) => {
    try {
      const response = await fetchUserAccessToken(id);
      removeCookie(process.env.REACT_APP_MERCHANT_TOKEN_NAME);
      setCookie(process.env.REACT_APP_MERCHANT_TOKEN_NAME, response.access_token, 1 / 24, '.sellonboard.com');
      const merchantDashboardWindow = window.open(process.env.REACT_APP_MERCHANT_DASHBOARD_URL, '_blank');
      merchantDashboardWindow.opener.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  const onToggleUpdateForm = () => {
    // in case hide update form, set selected currency is null
    if (visibleUpdateForm) setSelectedUser(null);

    setVisibleUpdateForm(!visibleUpdateForm);
  };

  const onUpdate = async (data) => {
    try {
      await updateUser(selectedUser.id, data);

      // refresh list
      navigate({
        pathname: location.pathname,
        search: stringifyQueryParams({}),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = (id) => {
    Modal.confirm({
      title: "Warning",
      content: "Are you sure to delete this record?",
      onOk: async () => {
        await deleteUser(id);
        // refresh list
        navigate({
          pathname: location.pathname,
          search: stringifyQueryParams({}),
        });
      },
    });
  };

  const onToggleAssignForm = () => {
    setVisibleAssignForm(!visibleAssignForm);
  };

  const onRefresh = () => {
    const query = parseQueryParams(location);
    getRecords(query);
  };

  const onAssign = async (data) => {
    try {
      const userIds = [...selectedRowKeys];

      await assignUsersToParent({
        user_ids: userIds,
        parent_id: data.parent_id,
      });

      onRefresh();
    } catch (err) {
      console.log(err);
    }
  };

  const onUnassign = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      onOk: async () => {
        try {
          const userIds = [id];

          await unassignUsersFromParent({
            user_ids: userIds,
          });

          onRefresh();
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  const onUpdateStatus = (id, status) => {
    Modal.confirm({
      title: `Are you sure to ${status ? "enable" : "disable"} this user?`,
      onOk: async () => {
        try {
          await updateUser(id, { status: status });

          onRefresh();
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      // onSelectRecords(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({}),
  };

  return (
    <div>
      <PageTitle titles={titles} />
      <TableBar showFilter={false} onSearch={onSearch} />
      <Row className="mb-16" justify="end" align="middle">
        <Button
          disabled={!selectedRowKeys.length}
          type="default"
          onClick={onToggleAssignForm}
        >
          Assign to Partner
        </Button>
        <Divider type="vertical" />
        <Button type="primary" onClick={onToggleCreateForm}>
          Create new Users
        </Button>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={records}
        rowKey="id"
        onChange={onChangeTable}
        pagination={{
          pageSize: perPage,
          total: totalCount,
          current: page,
        }}
      />
      <UserCreateForm
        visible={visibleCreateForm}
        onClose={onToggleCreateForm}
        onSubmit={onCreate}
      />
      <UserAssignForm
        visible={visibleAssignForm}
        onClose={onToggleAssignForm}
        onSubmit={onAssign}
      />
      {selectedUser && (
        <UserUpdateForm
          record={{ ...selectedUser, role: selectedUser.role.id }}
          visible={visibleUpdateForm}
          onClose={onToggleUpdateForm}
          onSubmit={onUpdate}
        />
      )}
    </div>
  );
};

export default UserList;
